<!-- 预警设置 -->

<template>
  <div class="warningSetting">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="nameValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleOpenDefaultDialog">管理默认设置</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="图片" width="110">
            <template slot-scope="scope">
              <el-image class="busilicense"
                :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''"
                fit="cover" @mouseover="previewImage(scope.row.picUrlList)">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="120" prop="validityPeriod" label="保质期(天)" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="firstWarnDay" label="第一次预警">
            <template slot-scope="scope">
              <div v-if="scope.row.firstWarnDay || scope.row.firstWarnPercent">
                {{ `${scope.row.firstWarnDay}天(${scope.row.firstWarnPercent}%)` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" prop="secondWarnDay" label="第二次预警(天)" show-overflow-tooltip
            width="115"></el-table-column>
          <el-table-column min-width="120" label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleSet(scope.row)">设置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog title="设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <h2>商品信息</h2>
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" readonly> </el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specs">
              <el-input v-model="ruleForm.specs" readonly> </el-input>
            </el-form-item>
            <el-form-item label="厂家" prop="factoryName">
              <el-input v-model="ruleForm.factoryName" readonly> </el-input>
            </el-form-item>
            <el-form-item class="day" label="保质期" prop="validityPeriod">
              <el-input v-model="ruleForm.validityPeriod" readonly>
                <template slot="append">天</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="categoryName">
              <el-input v-model="ruleForm.categoryName" readonly> </el-input>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" readonly> </el-input>
            </el-form-item>
            <el-form-item label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" readonly> </el-input>
            </el-form-item>
            <el-form-item label="商品条形码" prop="barCode">
              <el-input v-model="ruleForm.barCode" readonly> </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <h2>预警设置</h2>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第一次预警：保质期剩余" prop="first">
              <el-input v-model="ruleForm.firstWarnPercent" placeholder="请输入">
                <template slot="append">%</template>
              </el-input>
              <span class="left">时预警</span>
              <span class="left" v-if="warningDay">{{ warningDay }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第二次预警：保质期剩余" prop="second">
              <el-input v-model="ruleForm.secondWarnDay" placeholder="请输入" type="number" @change="checkedDay">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

    <!-- 管理默认设置弹窗 -->
    <el-dialog title="管理默认设置" :visible.sync="dialogDefaultVisible" @close="handleCloseDefaultDialog">
      <el-form :model="ruleDefaultForm" :rules="rulesDefault" ref="ruleForm" class="demo-ruleForm">
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第一次预警：保质期剩余" prop="first">
              <el-input v-model="ruleDefaultForm.first" placeholder="请输入">
                <template slot="append">%</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第二次预警：保质期剩余" prop="second">
              <el-input v-model="ruleDefaultForm.second" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="handleCloseDefaultDialog">关 闭</el-button>
        <el-button type="primary" @click="submitDefaultForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {

    return {
      loading: false,
      dayPass: false,
      barCode: '',
      nameValue: '', // 搜索商品名称
      // 表格数据
      tableData: [],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        firstWarnPercent: '',
        secondWarnDay: '',
        productName: '',
        specs: '',
        factoryName: '',
        expirationDay: '',
        categoryName: '',
        brand: '',
        enterBox: '',
        barCode: '',
        productId: '',
        supplierId: '',
      },
      rules: {
        firstWarnPercent: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        secondWarnDay: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
      },
      // 管理默认设置
      dialogDefaultVisible: false,
      ruleDefaultForm: {
        first: '',
        second: '',
      },
      rulesDefault: {
        first: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        second: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
      },
      // 图片预览
      showViewer: false,
      imgList: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getDeliveryProductList();
  },
  computed: {
    warningDay: {
      get() {
        if (
          !isNaN(this.ruleForm.validityPeriod) &&
          !isNaN(this.ruleForm.firstWarnPercent) &&
          this.ruleForm.validityPeriod != '' &&
          this.ruleForm.firstWarnPercent != ''
        ) {
          const validityPeriod = Number(this.ruleForm.validityPeriod);
          const firstWarnPercent = Number(this.ruleForm.firstWarnPercent);
          if (!isNaN(validityPeriod) && !isNaN(firstWarnPercent)) {
            return Math.floor(validityPeriod * (firstWarnPercent / 100)) + '天';
          }
        }
        return '';
      },
      set() {
        // 可以在需要时添加设置功能
      }
    }
  },

  methods: {
    getDeliveryProductList() {
      this.loading = true;
      this.$axios.get(this.$api.productList, {
        params: {
          productName: this.nameValue || null, // 搜索商品名称
          barCode: this.barCode || null,
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: 1,
        }
      }).then((res) => {
        if (res.data.code == 100) {

          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 设置
    handleSet(row) {
      console.log(row);
      this.dialogVisible = true;
      const {
        productName,
        specs,
        factoryName,
        expirationDay,
        categoryName,
        brand,
        enterBox,
        barCode,
        productId,
        supplierId,
        productWarnSetId,
        firstWarnPercent,
        secondWarnDay,
        validityPeriod
      } = row;
      this.ruleForm = {
        productName,
        specs,
        factoryName,
        expirationDay,
        categoryName,
        brand,
        enterBox,
        barCode,
        supplierId,
        productId,
        productWarnSetId,
        firstWarnPercent,
        secondWarnDay,
        validityPeriod
      };
    },
    // 检验天数
    checkedDay(value) {
      if (value >= parseFloat(this.warningDay)) {
        this.$message.warning('第二次预警天数不能大于第一次预警天数！');
        this.ruleForm.secondWarnDay = '';
        this.dayPass = true;
      } else {
        this.dayPass = false;
      }
    },
    // 打开默认弹窗
    handleOpenDefaultDialog() {
      this.dialogDefaultVisible = true;
      this.defaultSettingSelectById();
    },
    // 获取默认设置
    defaultSettingSelectById() {
      this.$axios.post(this.$api.defaultSettingSelectById, 1).then((res) => {
        if (res.data.code == 100) {
          this.ruleDefaultForm.first = res.data.result.firstWarnPercent;
          this.ruleDefaultForm.second = res.data.result.secondWarnDay;
        }
      })
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      if (this.dayPass == true) return;
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.warningSettings, {
            firstWarnDay: parseFloat(this.warningDay), // 第一天
            firstWarnPercent: this.ruleForm.firstWarnPercent, // 第一天百分比
            secondWarnDay: this.ruleForm.secondWarnDay, // 第二天
            productId: this.ruleForm.productId, // 商品id
            supplierId: this.ruleForm.supplierId, // 供应商id
            productWarnSetId: this.ruleForm.productWarnSetId, // 供应商id
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleCloseDialog();
              this.getDeliveryProductList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 关闭默认设置弹窗
    handleCloseDefaultDialog() {
      this.dialogDefaultVisible = false;
      this.resetDefaultForm();
    },
    // 默认设置弹窗表单校验
    submitDefaultForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.warningSettings, {
            // firstWarnDay: parseFloat(this.rulesDefault.first), // 第一天
            firstWarnPercent: this.ruleDefaultForm.first, // 第一天百分比
            secondWarnDay: this.ruleDefaultForm.second, // 第二天
            // productId: this.ruleForm.productId, // 商品id
            // supplierId: this.ruleForm.supplierId, // 供应商id
            productWarnSetId: 1, // 预警id
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              this.handleCloseDefaultDialog();
              this.getDeliveryProductList();
            }
          })
        }
      });
    },
    // 默认弹窗设置重置表单
    resetDefaultForm() {
      this.ruleDefaultForm.first = '';
      this.ruleDefaultForm.second = '';
      this.$refs.ruleForm.resetFields();
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getDeliveryProductList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getDeliveryProductList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getDeliveryProductList();
    },
    // 颜色变化
    reviewRowClass({
      row,
      columnIndex
    }) {
      if (columnIndex == 11 && row.expirationDate == '0') {
        return {
          color: '#E35B5A'
        };
      }
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
  }

}
</script>

<style lang="scss" scoped>
.warningSetting {

  .busilicense {
    display: block;
    width: 80px;
    height: 58px;
    border-radius: 4px;
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin: 0 20px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  .row-bottom {

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 255px;
    }

    ::v-deep .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    ::v-deep .el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    ::v-deep .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}

/* input number类型取消增减箭头*/
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
::v-deep .el-input__inner {
  line-height: 1px !important;
}

.day {

  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 200px;
  }

  ::v-deep .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  ::v-deep .el-input__inner {
    background: #f2f2f2;
    border-radius: 10px 0 0 10px;
    border: none;
  }

  ::v-deep .el-input-group__append {
    background: #f2f2f2;
    border-radius: 0px 10px 10px 0;
    border: none;
    color: #000;
  }
}
</style>
